<template>
  <div class="flex flex-col items-center">
    <div class="w-full xl:w-1/2 space-y-2">
      <a-button @click="$router.back()">
        Regresar
      </a-button>

      <form
        class="space-y-4"
        @submit.prevent="handleStore"
        @keydown="removeError($event.target.id)">
        <h3 class="my-4 text-2xl">Crear bitácora</h3>

        <a-input
          required
          class="mb-4"
          type="date"
          label="Fecha de la incidencia"
          id="incidence_date"
          :error="error('incidence_date')"
          v-model="binnacle.incidence_date" />

        <template v-if="user.isAdmin || user.isCoordinator">
          <a-select
            class="mb-4"
            label="Profesor"
            :required="user.isCoordinator"
            filterable
            @input="getCourses"
            :loading="loadingTeachers"
            :source="teachers.map(el => ({ label: `${el.last_name}, ${el.first_name}`, value: el.id }))"
            :error="error('teacher_id')"
            v-model="binnacle.teacher_id" />
        </template>

        <a-select
          label="Materia"
          required
          filterable
          class="mb-4"
          @input="getStudents"
          :loading="loadingCourses"
          :source="courses.map($0 => ({ label: `${$0.course.title} - ${$0.section.letter_code}`, value: $0.id }))"
          :error="error('course_section_id')"
          v-model="binnacle.course_section_id" />

        <a-select
          label="Estudiante"
          required
          class="mb-4"
          filterable
          @input="removeError('student_id')"
          :loading="loadingStudents"
          :source="teacherStudents.map($0 => ({ label: `${$0.last_name}, ${$0.first_name}`, value: $0.id }))"
          :error="error('student_id')"
          v-model="binnacle.student_id" />

        <a-input
          label="Descripción"
          required
          type="textarea"
          id="description"
          :error="error('description')"
          v-model="binnacle.description" />

        <div class="space-x-2 flex">
          <a-button mode="primary" class="mr-2" type="submit" :loading="loadingBinnacles">
            Guardar cambios
          </a-button>

          <a-button outlined :to="{ name: 'binnacles' }">
            Cancelar
          </a-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data: () => ({
    binnacle: {
      incidence_date: '',
      description: '',
      course_section_id: null,
      student_id: null,
      teacher_id: null
    },
    courseId: null,
    sectionId: null,
    courses: [], // just for the case when the logged user is teacher or coordinator
    teacherStudents: [], // just for the case when the logged user is teacher or coordinator
    isUpdating: false,
    period: {},
    loadingCourses: false,
    loadingStudents: false
  }),
  computed: {
    ...mapGetters({
      hasError: 'hasError',
      error: 'error',
      isTeacher: 'session/isTeacher',
      isAdmin: 'session/isAdmin',
    }),
    ...mapState({
      binnacles: state => state.binnacles.pagination.data,
      currentBinnacleOnState: state => state.binnacles.current,
      loadingBinnacles: state => state.binnacles.loading,
      // students: state => state.students.all,
      // loadingStudents: state => state.students.loading,
      teachers: state => state.teachers.all,
      loadingTeachers: state => state.teachers.loading,
      // courseSections: state => state.courseSections.all,
      teacher: ({ teachers }) => teachers.current,
      user: state => state.session.user,
    }),
    // teacherCourses() {
    //   let teacher = null
    //   if (this.user.isTeacher) {
    //     return this.courses
    //       .filter($0 => $0.period_id === this.period.id)
    //       .map(it => ({ label: `${it.course.title} - ${it.section.letter_code}`, value: it.id }))
    //   }
    //
    //   if (this.user.isAdmin || this.user.isCoordinator) {
    //     teacher = this.teachers.find(it => it.id === this.binnacle.teacher_id)
    //   }
    //
    //   if (teacher) {
    //     return teacher.course_sections
    //       .filter($0 => {
    //         return $0.period_id === this.period.id
    //       })
    //       .map(it => ({ label: `${it.course.title} - ${it.section.letter_code}`, value: it.id }))
    //   }
    //
    //   return []
    // },
    // mappedStudents() {
    //
    //   if (this.user.isTeacher) {
    //     return this.teacherStudents.map($0 => ({ label: `${$0.first_name} ${$0.last_name}`, value: $0.id }))
    //   }
    //
    //   let students = []
    //
    //   if (!this.binnacle.teacher_id) {
    //     return []
    //   }
    //
    //   if (!this.binnacle.course_section_id) {
    //     return []
    //   }
    //
    //
    //   this.teachers.find($0 => $0.id === this.binnacle.teacher_id).course_sections
    //     .forEach($0 => {
    //       if (this.binnacle.course_section_id === $0.id) {
    //         $0.students.forEach($1 => {
    //           if (!students.some($2 => $2.id === $1.id)) {
    //             students.push($1)
    //           }
    //         })
    //       }
    //     })
    //
    //   return students.map($0 => ({ label: `${$0.first_name} ${$0.last_name}`, value: $0.id }))
    // }
  },
  methods: {
    ...mapMutations({
      setNavbarTitle: 'SET_NAVBAR_TITLE'
    }),
    ...mapActions({
      fetchBinnacle: 'binnacles/show',
      store: 'binnacles/store',
      removeError: 'removeError',
      fetchStudents: 'students/index',
      fetchTeachers: 'teachers/index',
      fetchCourseSections: 'courseSections/index',
      fetchTeacher: 'teachers/show'
    }),
    getCourses() {
      this.loadingCourses = true
      this.$repository.teachers
        .courses(this.binnacle.teacher_id, { period: this.period.id, with: 'courseSection' })
        .then(({ data }) => {
          this.courses = data.data
        })
        .finally(() => this.loadingCourses = false)
    },
    getStudents(e) {
      this.course = this.courses.find($0 => $0.id === e)
      this.courseId = this.course?.course?.id
      this.sectionId = this.course?.section?.id

      this.loadingStudents = true
      this.$repository.sections.course(this.courseId)
          .students( this.sectionId, { period: this.period.id })
          .then(({ data }) => {
            this.teacherStudents = data.data
          })
          .finally(() => this.loadingStudents = false)
    },
    handleStore() {
      this.store(this.binnacle)
        .then(() => {
          this.$router.push({ name: 'binnacles' })
        })
    }
  },
  watch: {
    currentBinnacleOnState(val) {
      this.binnacle = {...val}
    },
    // 'binnacle.course_section_id'(val) {
    //   if (val && this.user.isTeacher) {
    //     const found = this.courses.find($0 => $0.id === val)
    //     if (found) {
    //       this.$repository.courseSections
    //         .show(found.section_id, found.course_id, {
    //           with: 'students'
    //         }).then(res => {
    //         this.teacherStudents = res.data.students
    //       })
    //     }
    //   }
    // }
  },
  mounted() {

    if (this.user.isAdmin || this.user.isCoordinator) {
      Promise.all([
        this.$repository.periods.enabled(),
        // this.fetchStudents({ limit: 0, enabled: true }),
        // this.fetchTeachers({ limit: 0, enabled: true, with: 'courseSections.course,courseSections.section' })
        this.fetchTeachers({ limit: 0, enabled: true, order_by: 'last_name,asc' })
      ])
        .then(([{ data: period }]) => {
          this.period = period
        })
    }

    // if (this.isTeacher) {
    //   Promise.all([
    //     this.$repository.periods.enabled(),
    //     this.$repository.teachers
    //       .courses(this.user.teacher.id, { limit: 0, with: 'section,students' })
    //   ]).then(([{data: period}, { data: courses }]) => {
    //     this.period = period
    //     this.courses = courses
    //   })
    // }
  }
}
</script>
